import { CommonModule } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  HostListener,
} from '@angular/core';
import {
  UIConfirmDialogConfig,
  UIConfirmDialogResult,
  UIDialogRef,
  UIModule,
} from '@bannerflow/ui';

@Component({
  selector: 'prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss'],
  imports: [CommonModule, UIModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromptDialogComponent implements OnDestroy {
  public config: UIConfirmDialogConfig;
  public messages: string[] = [];

  private resolve: (value: UIConfirmDialogResult) => void;
  private confirmPromise: Promise<UIConfirmDialogResult>;

  constructor(private dialog: UIDialogRef) {}

  /**
   * Initiate the prompt dialog component.
   * Will return a promise which will resolve to 'confirm' if confirmed by user.
   * If cancelled it will resolve to 'cancel'.
   */
  public initiate(): Promise<UIConfirmDialogResult> {
    this.messages = this.dialog.config.data?.messages;
    this.config = this.dialog.config;

    this.confirmPromise = new Promise<UIConfirmDialogResult>((resolve) => {
      this.resolve = resolve;
    });

    return this.confirmPromise;
  }

  /**
   * User pressed confirm
   */
  public confirm(): void {
    this.resolve('confirm');
    this.dialog.close();
  }

  /**
   * User cancelled
   */
  public cancel(): void {
    this.resolve('cancel');
    this.dialog.close();
  }

  /**
   * Clean up component when removed
   */
  public ngOnDestroy(): void {
    if (this.resolve) {
      this.resolve('discard');
    }
  }

  @HostListener('window:keyup', ['$event'])
  public keyEvent(event: KeyboardEvent): void {
    if (event.code === 'Enter') {
      this.confirm();
    } else if (event.code === 'Escape') {
      this.cancel();
    }
  }
}
