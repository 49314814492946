<div class="fileBrowser__folder"
    [ngClass]="{ 'fileBrowser__folder--collapsed': collapsed }"
    *ngIf="stateEnum.Deleted !== item.state">

     <!-- Header: Name and buttons of folder -->
    <div class="fileBrowser__folderHeader fileBrowser__folderHeader--indent{{depth}}"
        *ngIf="depth > 0"
        (click)="toggleCollapsed()">
        <div class="fileBrowser__folderHeaderContainer" [bfContextMenu]="config">
            <div    class="fileBrowser__folderCollapse"
                    *ngIf="depth > 0 && (item.folders.length || item.files.length)">
                <i class="bf-icon bf-icon-caret-down"></i>
            </div>
            <i class="bf-icon bf-icon-fw bf-icon-folder"></i>
            <!--
            <input  #bfInlineEditReference=bfInlineEdit
                    [(bfInlineEdit)]="item.name"
                    (done)="onItemNameChange($event)"
                    (change)="onItemNameEdit($event)"
                    class="fileBrowser__folderName">-->

             <div class="fileBrowser__folderNameContainer fileBrowser__folderNameContainer--{{validName ? 'valid' : 'invalid' }}">
                <div  #bfInlineEditReference=bfInlineEdit
                        *ngIf="!locked"
                        [(bfInlineEdit)]="itemName"
                        (bfInlineEditAbort)="abort()"
                        (bfInlineEditDone)="onItemNameChange($event)"
                        (bfInlineEditEdit)="onItemNameEdit($event)"
                        [bfInlineEditPreventClickToEdit]="true"
                        class="fileBrowser__folderName"></div>
                <span class="fileBrowser__folderNameSpan" *ngIf="locked" >{{item.name}}</span>
                <div class="fileBrowser__folderNameError" *ngIf="!validName">{{invalidMessage}}</div>
            </div>

            <span   myHighlight="blue"
                    *ngIf="depth === 0">{{item.name}}</span>

            <span class="fileBrowser__folderHeaderButtons">
                <!--<i class="bf-icon bf-icon-fw bf-icon-plus" (click)="addFile($event)"></i>
                <i class="bf-icon bf-icon-fw bf-icon-folder-o" (click)="addFolder($event)"></i>
                <i class="bf-icon bf-icon-fw bf-icon-close" (click)="remove($event)"></i>-->
                <i class="bf-icon bf-icon-fw bf-icon-plus" [bfMenu]="config"></i>
            </span>
        </div>
    </div>

    <div *ngIf="!collapsed">
        <!-- Files -->
        <div *ngFor="let file of item.files | bfOrderBy: ['sortName']; let i = index;" [style.zIndex]="1000 - i">
            <fileBrowserFile    [item]="file"
                                [depth]="depth+1"
                                [parentFolder]="item" />
        </div>
    </div>
</div>
