// libs
import { Component } from '@angular/core';
import {
  BFNotificationService
} from '../../../../libs/material/index';
import {ActivatedRoute, Router, RouterLink, RouterOutlet} from "@angular/router";
import {NgIf} from "@angular/common";
import {LandingPageService} from "shared/services/landingPage.service";
import {TextService} from "shared/services/text.service";
import {EllipsisMiddlePipe} from "shared/pipes/ellipsisMiddle.pipe";
import {UserService} from "shared/services/user.service";
import {ApiService} from "shared/services/api.service";
import {HotkeysService} from "shared/services/hotkeys.service";
import {LandingPageModel} from "shared/models/landingPage.model";
import {TranslationModel} from "shared/models/translation.model";
import {UIConfirmDialogResult, UIModule} from "@bannerflow/ui";
import { LPBConfirmDialogService, ModalType } from 'shared/services/lpb-confirm-dialog.service';

@Component({
    selector: 'translatorView',
    styleUrls: ['translation.view.component.scss'],
    templateUrl: 'translation.view.component.html',
    standalone: true,
  imports: [RouterLink, NgIf, RouterOutlet, EllipsisMiddlePipe, UIModule]
})
export class TranslationViewComponent {
    public backLink: string;
    public landingPageId: string;
    public translatorId: string;
    public isSaving = false;

    constructor(public landingPageService: LandingPageService,
        public textService: TextService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly userService: UserService,
        private readonly apiService: ApiService,
        private readonly lpbDialogService: LPBConfirmDialogService,
        private readonly router: Router,
        private readonly notificationService: BFNotificationService,
        private readonly hotkeysService: HotkeysService) {


        this.hotkeysService.bindKey(['command+s', 'ctrl+s'], (event) => {
            this.save();
        });

        this.activatedRoute.params.subscribe((params: any) => {
            this.userService.get()
                .then((user) => {
                    if (params.translationId) {
                        this.landingPageService.get().then((landingPage: LandingPageModel) => {
                            this.landingPageId = landingPage.id;
                            this.backLink = `/translator/${params.translatorId}`;
                            this.translatorId = params.translatorId;
                            let translation: TranslationModel = landingPage.translations.find((t: TranslationModel) => {
                                return t.id === params.translationId;
                            });
                            this.textService.setCurrentTranslation(translation);
                        });
                    }
                });
        });
    }

    public async complete(): Promise<void> {
        const confirmHandler = () => {
            let action =
                `complete?translationId=${this.textService.currentTranslation.id}&translatorId=${this.translatorId}&landingPageId=${this.landingPageId}`;

            return this.apiService.get('externalTranslation', action)
                .then(() => {
                    this.router.navigate(['/translator', this.translatorId]);
                });
        };

        const confirmResult: UIConfirmDialogResult =
        await this.lpbDialogService.showDialog(
          ModalType.completeTranslationConfig,
        );

        if (confirmResult === 'confirm') {
            try {
                await confirmHandler();
            } catch (err) {
                this.notificationService.show(
                    `Something went wrong while completing translation`,
                    'error',
                    'top',
                    undefined,
                    'alert'
                );
            }
        }
    }

    public async save(): Promise<void> {
      if (!this.landingPageService.dirty) {
          return;
      }

      this.isSaving = true;
      let data = { texts: [], resourceTranslations: [] };

      if (this.textService.currentTranslation.texts) {
          this.textService.currentTranslation.texts.forEach((t) => {
              data.texts.push({ key: t.key, value: t.value });
          });
      }

      if (this.textService.currentTranslation.resourceTranslations) {
          this.textService.currentTranslation.resourceTranslations.forEach((t) => {
              data.resourceTranslations.push({ resourceId: t.resourceId, originalResourceId: t.originalResourceId });
          });
      }

      this.landingPageService.toggleDirty(false);
      const action = `save?landingPageId=${this.landingPageId}&translationId=${this.textService.currentTranslation.id}`;

      try {
          const response = await this.apiService.post('externalTranslation', action, data);
          if (response) {
            this.isSaving = false;
            this.notificationService.show('Your translation is saved', 'success', 'top', undefined, 'finished');
          }
      } catch (error) {
          this.isSaving = false;
          this.landingPageService.toggleDirty(true);
      }
  }


}
