import { BFMenuConfig, BFMenuConfigOptions, BFMenuItem } from '../../../../../libs/material/index';
import { Component } from '@angular/core';
import { PickSizeComponent } from './pickSize.component';
import { NgIf, NgClass, CommonModule } from '@angular/common';
import { AppService } from '../../../../app.service';
import { ActivatedRoute, NavigationEnd } from '@angular/router';
import { Router } from '@angular/router';
import { filter, map, switchMap } from 'rxjs';
import { BFTooltipDirective } from '../../../../../libs/material/components/tooltip/bfTooltip.directive';
import { BFMenuDirective } from '../../../../../libs/material/directives/menu/bfMenu.directive';
import {LandingPageModel} from "shared/models/landingPage.model";
import {LandingPageService} from "shared/services/landingPage.service";
import {HotkeysService} from "shared/services/hotkeys.service";
import {PublishService} from "shared/services/publish.service";
import {TextService} from "shared/services/text.service";
import {DesignService} from "design/design.service";
import {TranslationModel} from "shared/models/translation.model";
import { UtilsService } from 'shared/services/utils.service';
import {EnvironmentPickerComponent} from "shared/components/environment-picker/environment-picker.component";
import { UIModule } from "@bannerflow/ui";
import { LandingPageStore } from '../../../../landingPage.component.store';

@Component({
    selector: 'header',
    styleUrls: ['header.component.scss'],
    templateUrl: 'header.component.html',
    standalone: true,
  imports: [NgIf, BFTooltipDirective, NgClass, BFMenuDirective, EnvironmentPickerComponent, UIModule, CommonModule]
})
export class HeaderComponent extends PickSizeComponent {
    public view = '';
    public translationsMenuConfig: BFMenuConfig;
    public landingPage: LandingPageModel;
    public isSaving = false;
    public selected: any;
    public TranslationModel = TranslationModel;
    public UtilsService = UtilsService;

    constructor(
        public landingPageService: LandingPageService,
        private landingPageStore: LandingPageStore,
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        protected readonly appService: AppService,
        private readonly hotkeysService: HotkeysService,
        private readonly publishService: PublishService,
        private readonly _designService: DesignService,
        public readonly _textService: TextService
    ){

        super(_designService, _textService);

        this.router.events.pipe(
            filter((event): event is NavigationEnd => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map(route => route.firstChild),
            switchMap(route => route.data),
            map(data => data['view'])
        ).subscribe((view: string) => {
            this.view = view;
        });

        this.hotkeysService.bindKey(['command+s', 'ctrl+s'], () => {
            this.save();
        });

        this.loadLandingPage();
        this.landingPageService.landingPageSaved.subscribe(this.loadLandingPage.bind(this));
        this.landingPageService.translationsChange.subscribe(this.loadLandingPage.bind(this));
    }

    public loadLandingPage(): void {
        this.landingPageService.get().then((landingPage: LandingPageModel) => {
            this.landingPage = landingPage;
            this.landingPage.name = this.truncateLongTitle();
            this.buildTranslationMenu();
        });
    }

    private getTranslationMenuItem(translation: TranslationModel): BFMenuItem {
        let menuItem = new BFMenuItem(translation.name, () => {
            this.router.navigate(
                [translation.id, "design"],
                { relativeTo: this.activatedRoute });
        });

        menuItem.flag = translation.culture;

        return menuItem;
    }

    private buildTranslationMenu(): void {
        let menuItems = new Array<BFMenuItem>();

        // All translations except original
        this.landingPage.translations.forEach((translation: TranslationModel) => {
            let item = this.getTranslationMenuItem(translation);
            menuItems.push(item);
        });

        // Sort items in alphabetic order
        menuItems.sort((a: BFMenuItem, b: BFMenuItem) => {
            return a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1;
        });

        // Original translation menu item
        let originalTranslationMenuItem =
            this.getTranslationMenuItem(this.landingPage.originalTranslation);
        menuItems.unshift(originalTranslationMenuItem);
        menuItems[0].description = 'Original';

        // divider
        menuItems.unshift(new BFMenuItem('', null, true));

        // 'Manage versions' menu item
        let manageVersionsMenuItem = new BFMenuItem('Manage versions', () => {
            this.router.navigate(
                ['versions'],
                { relativeTo: this.activatedRoute }
            );
        });
        manageVersionsMenuItem.icon = 'more';
        menuItems.unshift(manageVersionsMenuItem);

        this.translationsMenuConfig = new BFMenuConfig(menuItems,
            new BFMenuConfigOptions('bottom', { top: -2 }, true)
        );
    }

    public async save(): Promise<void> {
      if (this.isSaving) return;

      this.isSaving = true;
      try {
        const savePromise = this.landingPageService.save();
        this.landingPage = await savePromise;
      } catch (error) {
        console.error("Error saving landing page", error);
      } finally {
        this.isSaving = false;
      }
    }

    public publish(translation: TranslationModel): void {
        this.publishService.getCnames().then((cnames) => {
            this.landingPageService.get().then((landingPage: LandingPageModel) => {
                this.landingPageStore.setlandingPage(landingPage);
                this.landingPageStore.setSelectedTranslations([translation]);
                this.landingPageStore.setCnames(cnames);
                this.landingPageStore.setType('publish');
                this.router.navigate(
                  ['publish'],
                  { relativeTo: this.activatedRoute }
                );
            });
        });
    }

    protected unpublish(translation: TranslationModel): Promise<void> {
        return this.publishService.getCnames().then((cnames) => {
            return this.landingPageService.get().then((landingPage: LandingPageModel) => {
                this.landingPageStore.setlandingPage(landingPage);
                this.landingPageStore.setSelectedTranslations([translation]);
                this.landingPageStore.setCnames(cnames);
                this.landingPageStore.setType('unpublish');
                this.router.navigate(
                  ['publish'],
                  { relativeTo: this.activatedRoute }
                );
            });
        });
    }

    public truncateLongTitle(): string {

        let limit = 50;
        let title = this.landingPage.name;
        let truncationDots = '...';

        if (title.length > limit)
            return title.substring(0, limit) + truncationDots;
        else
            return title;
    }
}
