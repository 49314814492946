import { showcaseRoutes } from 'showcase/showcase.routes';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import { Component, OnInit } from '@angular/core';
import { MainViewComponent } from 'views/main/main.view.component';
import { CodeViewComponent } from 'code/views/code/code.view.component';
import { DesignViewComponent } from 'design/views/design/design.view.component';
import { externalTranslationRoutes } from 'externalTranslation/externalTranslation.routes';
import { publishRoutes } from './publish/publish.routes';
import { versionsRoutes } from './versions/versions.routes';
import { VersionsComponent } from './versions/views/versions/versions.component';

@Component({
	standalone: true,
	template: '',
})
export class ExternalRedirectComponent implements OnInit {
	constructor(
		private route: ActivatedRoute,
		private router: Router,
	) {}

	ngOnInit() {
		this.route.data.subscribe((data) => {
			window.location.href = data['externalUrl'];
		});
	}
}

export const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		canActivate: [AuthGuard],
		component: ExternalRedirectComponent,
		data: {
			externalUrl: environment.HOME_URL,
		},
	},
	{
		path: '',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		children: [
			{
				path: 'editor/:accountId/:brandId/:id',
				component: MainViewComponent,
				children: [
					{
						path: 'code',
						component: CodeViewComponent,
						pathMatch: 'full',
						data: {
							view: 'code',
						},
					},
					{
						path: '',
						redirectTo: 'design',
						pathMatch: 'full',
					},
					{
						path: ':translationId/design',
						component: DesignViewComponent,
						pathMatch: 'full',
						data: {
							view: 'design',
						},
					},
					{
						path: 'design',
						component: DesignViewComponent,
						pathMatch: 'full',
						data: {
							view: 'design',
						},
					},
					{
						path: 'versions',
						component: VersionsComponent,
						pathMatch: 'full',
						data: {
							view: 'versions',
						},
					},
				],
			},
			...showcaseRoutes.map((route) => ({ ...route })),
			...externalTranslationRoutes.map((route) => ({ ...route })),
			...publishRoutes.map((route) => ({ ...route })),
			...versionsRoutes.map((route) => ({ ...route })),
		],
	},
];

export const AppRoutes = RouterModule.forRoot(routes, {
	initialNavigation: 'enabledBlocking',
	enableTracing: environment.NAME === 'local',
});
