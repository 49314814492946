<div class="app">
    <div class="app__top">
        <header></header>
    </div>
    <div class="app__page">
        <router-outlet></router-outlet>
    </div>
    <div *ngIf="!versionsRoute" class="app__bottom">
        <footer [designRoute]="designRoute"></footer>
    </div>
</div>