// libs
import { Injectable, EventEmitter } from '@angular/core';
import {UserService} from "shared/services/user.service";
import {LandingPageService} from "shared/services/landingPage.service";
import {FileModel} from "shared/models/file.model";
import {FileType} from "shared/enums/fileType.enum";

@Injectable({ providedIn: 'root' })
export class DocumentService {
    public activeDocument: Document;
    public activeDocumentChange: EventEmitter<Document> = new EventEmitter<Document>();

    public openDocuments: Document[] = [];

    constructor(private userService: UserService,
        private landingPageService: LandingPageService) {

    }

    public isFileActive(file: FileModel): boolean {
        if (!this.activeDocument)
            return false;

        return this.activeDocument.file === file;
    }

    public getDocumentWithFile(file: FileModel): Document {
        return this.openDocuments.find((document: Document) => { return file === document.file; });
    }

    public setActiveDocument(document: Document): void {
        this.activeDocument = document;
        this.activeDocumentChange.emit(document);
    }

    public openFile(file: FileModel): void {
        let document: Document = this.getDocumentWithFile(file);

        if (file.url == null && file.stringContent == null) {
            this.setFileContent(file).then(() => {
                if (!document) {
                    document = new Document(file);
                    this.openDocuments.push(document);
                }

                this.setActiveDocument(document);
            });
        }
        else {
            if (!document) {
                document = new Document(file);
                this.openDocuments.push(document);
            }

            this.setActiveDocument(document);
        }
    }

    public closeDocument(document: Document): void {
        let index: number = this.openDocuments.indexOf(document);

        if (index >= 0) {
            this.openDocuments.splice(index, 1);

            if (this.openDocuments.length)
                this.setActiveDocument(this.openDocuments[0]);
            else
                this.setActiveDocument(null);
        }
    }

    private setFileContent(file: FileModel): Promise<FileModel> {
        ;

        if (file.stringContent != null)
            return Promise.resolve(file);

        var promise: Promise<FileModel> = new Promise<FileModel>((resolve) => {

            // todo: user user service
            this.userService.get().then((user) => {
                this.landingPageService
                    .getFileContent(user.accountSlug, user.brandId, file.id)
                    .then((content: string) => {
                        if (file.type === FileType.Text) {
                            file.setStringContent(content || '');
                        } else {
                            file.url = content;
                        }

                        resolve(file);
                    })
                    .catch(response => {
                        resolve(file);
                        console.error(`Could not load the Resource content for resource ${file.id}!`, response);
                    });
            });
        });

        return promise;
    }
}

export class Document {
    public file: FileModel;
    public scrollPosition: any;
    public selection: any;
    public history: any;

    constructor(file: FileModel) {
        this.file = file;
    }
}
