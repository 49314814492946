import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { SelectItemModel } from 'shared/models/selectItem.model';


@Component({
    selector: 'bfSearch',
    templateUrl: 'bfSearch.component.html',
    standalone: true,
})

export class BFSearchComponent implements OnInit, OnDestroy, OnChanges {

    private originalArray: any[];
    public newArray: any[];

    @ViewChild('input', { static: true }) public input: ElementRef;
    @Input() public placeholder: string = 'Type to search...';

    @Input() public type: string;
    public isBfSelect: boolean = false;

    @Output() public searchChange: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Input() public search: any[];


    constructor(private element: ElementRef) { }

    public ngOnInit(): void {
        this.isBfSelect = this.type === 'bfSelect' ? true : false;
        this.originalArray = this.search;
    }

    public ngOnDestroy(): void {
        if (this.isBfSelect) {
            this.originalArray.map((item) => {
                item.hidden = false;
                return item;
            })
        }
        this.searchChange.emit(this.originalArray);
    }

    public ngOnChanges(change: SimpleChanges): void {
        if (this.isBfSelect) {
            this.originalArray = change.search.currentValue;
        }
    }

    public onKeyUp(): void {
        let inputValue: string = this.input.nativeElement.value;

        if (this.isBfSelect) {
            this.newArray = this.originalArray.map((item: any) => {
                let newItem: SelectItemModel = new SelectItemModel();
                newItem = item;
                if (item.label && item.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) {
                    newItem.hidden = false;
                }
                else {
                    newItem.hidden = true;
                }
                return newItem;
            });
        }
        else {
            this.newArray = this.originalArray.filter((item) =>
                item.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            );
        }

        if (this.newArray.length) {
            this.searchChange.emit(this.newArray);
        }
        else {
            this.searchChange.emit(this.originalArray);
        }
    }

}
