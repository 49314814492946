import { SelectItemModel } from "./selectItem.model";

export class SelectConfigModel {
    public selectItems: Array<SelectItemModel>;
    public options: SelectConfigOptions;

    constructor(selectItems?: Array<SelectItemModel>, options?: SelectConfigOptions) {
        this.selectItems = selectItems || new Array<SelectItemModel>();
        this.options = options || new SelectConfigOptions();
    }
}

export class SelectConfigOptions {
    public placement: string = 'bottom-left';
    public closeOnSelect: boolean = true;
    public width: number = 0;
    public fullWidth: boolean = false;
    public multi: boolean = false;
    public maxSelectedItems: number;
    public placeholder: string = 'Nothing selected';
    public searchable: boolean = false;

    constructor(multi?: boolean | null, placement?:string | null, placeholder?:string | null) {
        if(multi){
            this.multi = true;
            this.closeOnSelect = false;
        }
        if(placement){
            this.placement = placement;
        }
        if(placeholder){
            this.placeholder = placeholder;
        }
    }
}
