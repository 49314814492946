import { Environment } from './environment.type';

export const environment: Environment = {
	NAME: 'production',

	LOGIN_URL: 'https://login.bannerflow.com',
	CLIENT_ID: 'mYcENU25Mr5NCszO1iJoF2ZCaRIfrfh9',
	API_URL: 'https://api.bannerflow.com/landing-page-builder',
	HOME_URL: 'https://home.bannerflow.com',
	ARTBOARD_BASE_PATH: 'landing-page-builder',

	NEW_RELIC_ACCOUNT_ID: '4122654',
	NEW_RELIC_APPLICATION_ID: '538568984',
	NEW_RELIC_LICENSE_KEY: 'NRJS-bd28b6acdc31b77b97c',
	NEW_RELIC_TRUST_KEY: '4122654',
	RELEASE_NAME: '',
	VERSION: ''
};
